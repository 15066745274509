import React from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash.throttle';
import { geocodeByPlaceId } from 'react-places-autocomplete';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

type TProps = {
  onChangeHandler: (val: string | null, placeId: string | null) => void;
  withGeoCode?: boolean;
  value?: string;
  error?: boolean;
  style?: any;
  label?: string;
  variant?: any;
  className?: string;
};
export default function Autocomplete({
  onChangeHandler,
  value,
  error,
  style,
  withGeoCode,
  label,
  variant,
  className,
}: TProps) {
  const classes = useStyles();
  const [placeValue, setPlaceValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState(value || '');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBPl1xapz4EUjvWoOk8GGW31-keREZQ-r8&libraries=places&&region=PK',
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(
          {
            ...request,
            types: ['(cities)'],
            componentRestrictions: {
              country: 'pk',
            },
          },
          callback
        );
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(placeValue ? [placeValue] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (placeValue) {
          newOptions = [placeValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [placeValue, inputValue, fetch]);

  return (
    <MuiAutocomplete
      style={style}
      className={className}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      // @ts-ignore
      value={value}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue) {
          if (withGeoCode) {
            geocodeByPlaceId(newValue.place_id)
              .then((results: any) => {
                const address = results[0].formatted_address.split(', Pakistan')[0];
                setPlaceValue(address);
                onChangeHandler(address, results[0].place_id);
              })
              .catch((err: any) => console.error(err));
          } else {
            // @ts-ignore
            setPlaceValue(newValue.description);
            onChangeHandler(newValue.description, newValue.place_id);
          }
        } else {
          setPlaceValue(newValue);
          onChangeHandler(newValue, null);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Add a location'}
          variant={variant || 'outlined'}
          fullWidth
          value={value}
          error={error}
        />
      )}
      renderOption={(option) => {
        if (typeof option === 'object') {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length])
          );
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part: any, index: number) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        return null;
      }}
    />
  );
}
